export default class ToggleDisplay {
  constructor(scope) {
    this.elements = scope.querySelectorAll('[data-toggle-display]');
    this.scope = scope;
  }

  render() {
    let scope = this.scope;
    this.elements.forEach(link => {
      link.addEventListener("click", function (e) {
        e.preventDefault();
        let targets = scope.querySelectorAll(e.currentTarget.dataset.toggleDisplay);
        targets.forEach((target) => {
          target.style.display = target.style.display === 'none' ? '' : 'none';
        });
      });
    });
  }
};
