<template>
  <div>
    <div class="form-group">
      <label>{{inputLabel}}</label>
      <input type="hidden" v-bind:name="inputName" value="" />
      <select multiple="true" v-bind:name="inputName" style="display: none;">
        <option v-for="value in values" :value="value" selected="true">{{value}}</option>
      </select>
      <multiselect v-model=values :options="options" :taggable="true" :multiple="true" :hide-selected="true"
                   :close-on-select="false"
                   @tag="addTag"
                   selectLabel=""
                   deselectLabel=""
                   selectGroupLabel=""
                   deselectGroupLabel=""
                   tagPlaceholder=""
                   placeholder=""></multiselect>
    </div>
  </div>
</template>

<script>
  import Multiselect from 'vue-multiselect'

  export default {
    components: {Multiselect},
    props: [
      "initialOptions",
      "selected",
      "inputName",
      "inputLabel"
    ],
    data: function () {
      return {
        options: this.initialOptions,
        values: this.selected
      }
    },
    methods: {
      addTag(name) {
        this.options.push(name);
        this.values.push(name);
      }
    }
  }
</script>
