<script>
  const _ = require('lodash');
  import draggable from 'vuedraggable'

  export default {
    components: {
      draggable
    },
    props: ["exercises", "initialAssessmentExercises", "initialSectionNames", "enableKcLibrary"],
    data: function () {
      let assessmentExercises = this.initialAssessmentExercises;
      let sections = _.map(this.initialSectionNames, (name, i) => {
        return {name: name, id: i}
      });
      if (sections.length <= 0) {
        sections.push({
          name: "Section 1",
          id: 1
        });
      }
      assessmentExercises.forEach((aE) => {
        aE._destroy = null; // setting null value to ensure properties is "watched" when removing exercise
        aE.section = _.find(sections, {name: aE.section_name});
      });
      return {
        assessmentExercises: assessmentExercises,
        currentOrganizationId: null,
        sections: sections,
        currentSection: sections[0],
        exerciseFilter: ""
      }
    },
    computed: {
      orderedAssessmentExercises: {
        get() {
          this.assessmentExercises.forEach((aE) => {
            aE.exercise = _.find(this.exercises, {id: aE.exercise_id});
          });
          return _.orderBy(this.assessmentExercises, 'position')
        },
        set(value) {
          let assessmentExercises = this.assessmentExercises;
          assessmentExercises.forEach((aE) => {
            aE.position = _.findIndex(value, {exercise_id: aE.exercise_id})
          });
          this.assessmentExercises = _.orderBy(assessmentExercises, 'position')
        }
      },
      availableExercises: function () {
        let availableExercises = this.exercises;
        // - dont include assigned exercises except if destroyed
        availableExercises = availableExercises.filter((exercise) => {
          let assessmentExercise = _.find(this.assessmentExercises, {exercise_id: exercise.id});
          return !assessmentExercise || assessmentExercise._destroy != null
        });
        // - filter by currentOrganizationId
        availableExercises = _.filter(availableExercises, {organization_id: this.currentOrganizationId});
        // - dont include draft exercise
        availableExercises = _.filter(availableExercises, {draft: false});
        // - dont include deleted exercises
        availableExercises = _.filter(availableExercises, {deleted_at: null});
        // - filter with query
        availableExercises = _.filter(availableExercises, (exercise) => {
          return (exercise.title.concat(exercise.description)).toLowerCase().includes(this.exerciseFilter.toLowerCase());
        });
        return availableExercises
      },
      notDeletedAssessmentExercises: function () {
        return this.assessmentExercises.filter((aE) => {
          return aE._destroy == null
        })
      },
      deletedAssessmentExercises: function () {
        return this.assessmentExercises.filter((aE) => {
          return aE._destroy != null
        })
      }
    },
    methods: {
      addExercise: function (e, exercise_id) {
        e.preventDefault();
        let deletedAssessmentExercise = _.find(this.deletedAssessmentExercises, {exercise_id: exercise_id});
        if (deletedAssessmentExercise) {
          deletedAssessmentExercise._destroy = null;
          deletedAssessmentExercise.section = this.currentSection;
        } else {
          this.assessmentExercises.push({
            exercise_id: exercise_id,
            section: this.currentSection
          });
        }
        this.$_setAssessmentExercisesPosition();
      },
      removeExercise: function (e, exercise_id) {
        e.preventDefault();
        let assessmentExercise = _.find(this.assessmentExercises, {exercise_id: exercise_id});
        this.$_removeAssessmentExercise(assessmentExercise);
      },
      $_removeAssessmentExercise: function (assessmentExercise) {
        if (assessmentExercise.id == null) {
          let index = _.findIndex(this.assessmentExercises, {exercise_id: assessmentExercise.exercise_id});
          this.assessmentExercises.splice(index, 1)
        } else {
          assessmentExercise._destroy = "1";
        }
        this.$nextTick(this.$_setAssessmentExercisesPosition);
      },
      $_setAssessmentExercisesPosition: function () {
        let index = 0;
        this.sections.forEach((section) => {
          _.filter(this.notDeletedAssessmentExercises, {section: section}).forEach(function (aE) {
            aE.position = index += 1;
          });
        });
      },
      setCurrentOrganization: function (e, id) {
        e.preventDefault();
        this.currentOrganizationId = id;
      },
      filterAvailableExercises: function (e) {
        this.exerciseFilter = e.currentTarget.value;
      },
      addNewSection: function (e) {
        e.preventDefault();
        this.sections.push({
          name: "Section " + (this.sections.length + 1).toString(),
          id: this.sections.length
        });
        this.currentSection = _.last(this.sections);
      },
      removeSection: function (e, section) {
        e.preventDefault();
        if (this.sections.length > 1) {
          let assessmentExercisesToRemove = _.filter(this.assessmentExercises, {section: section}); // first identify aE to remove then clean the list (elsif removing during loop while omit someone)
          assessmentExercisesToRemove.forEach((aE) => {
            this.$_removeAssessmentExercise(aE);
          });
          let index = _.indexOf(this.sections, section);
          this.sections.splice(index, 1);
          this.currentSection = this.sections[index <= 0 ? 0 : index - 1];
        }
      },
      setCurrentSection: function (e, section) {
        e.preventDefault();
        this.currentSection = section;
      },
      disableExercise: function() {
        return this.currentOrganizationId == null && !this.enableKcLibrary
      }
    }
  }
</script>
