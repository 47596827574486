// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

var Turbolinks = require("turbolinks")
Turbolinks.start()
Turbolinks.setProgressBarDelay(100)

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require('bootstrap')
require('highlight.js')

require.context('../src/images', true)

import Vue from 'vue/dist/vue.esm'
import {Application} from "stimulus"
import {definitionsFromContext} from "stimulus/webpack-helpers"

import McqChoices from '../packs/components/mcq-choices'
import AssessmentExercises from '../packs/components/assessment-exercises'
import ExerciseHeader from '../packs/components/exercise-header'
import FormTags from '../packs/components/form-tags'
import EvaluationCountdown from '../packs/components/evaluation-countdown'

Vue.component('exercise-mcq-choices', McqChoices)
Vue.component('assessment-exercises', AssessmentExercises)
Vue.component('exercise-header', ExerciseHeader)
Vue.component('form-tags', FormTags)
Vue.component('evaluation-countdown', EvaluationCountdown)

import I18n from '../utils/i18n_js.js.erb'

Vue.mixin({
  methods: {
    t: (...args) => I18n.t(...args),
    underscore: (str) => str.split("::").map(s => _.snakeCase(s)).join("/")
  }
})

import "../utils/direct_uploads.js"
import FormFileInput from '../src/javascript/form_file_input'
import TextareaEditor from '../src/javascript/textarea_editor'
import Highlight from '../src/javascript/highlight'
import FormLiveSearch from '../src/javascript/form_live_search'
import ToggleDisplay from '../src/javascript/toggle_display'
import setupAxiosDefault from '../src/javascript/setup_axios_default'
import AssessmentNewForm from '../src/javascript/assessment_new_form'


setupAxiosDefault();

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

let vm = undefined;
document.addEventListener('turbolinks:load', () => {
  window.App.setup(document);
});

window.App = {
  setup(scope) {
    if (scope !== document) {
      scope = document.querySelector(scope)
    }
    scope.querySelectorAll('[data-behaviour="vue"]').forEach((el) => {
      vm = new Vue({el: el})
    })
    new FormFileInput(scope).render()
    new TextareaEditor(scope).render()
    new Highlight(scope).render()
    new FormLiveSearch(scope).render()
    new ToggleDisplay(scope).render()
    new AssessmentNewForm(scope).render()
  }
}


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../src/images', true)
// const imagePath = (name) => images(name, true)
