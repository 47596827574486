import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["revealable"]

  toggle(e) {
    e.preventDefault();

    this.revealableTargets.forEach((el) => {
      el.classList.toggle("d-none");
    });

    let icon = e.currentTarget.querySelector("i.fa");
    if (icon) {
      if (icon.classList.contains('fa-chevron-down')) {
        icon.classList.remove("fa-chevron-down");
        icon.classList.add("fa-chevron-up");
      } else {
        if (icon.classList.contains('fa-chevron-up')) {
          icon.classList.remove("fa-chevron-up");
          icon.classList.add("fa-chevron-down");
        }
      }
    }
  }
}

