import {Controller} from "stimulus"

const axios = require('axios')

export default class extends Controller {
  connect() {
    this.url = this.element.href
  }

  process(e) {
    const stripe = Stripe(this.data.get("publicKey"))
    e.preventDefault()
    e.currentTarget.classList.add("disabled")
    axios.post(this.url)
        .then((response) => {
          return response.data
        })
        .then((session_id) => {
          return stripe.redirectToCheckout({sessionId: session_id})
        })
        .then((result) => {
          // If `redirectToCheckout` fails due to a browser or network
          // error, you should display the localized error message to your
          // customer using `error.message`.
          if (result.error) {
            alert(result.error.message)
          }
        })
        .catch(function (error) {
          console.error('Error:', error)
          alert(error.response.statusText)
        })
  }
}

