import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["follower"]

  connect() {
    if (this.hasFollowerTarget) {
      this.element.classList.add("clickable-list-enabled");
    }
  }

  follow(e) {
    if (this.hasFollowerTarget) {
      if (e.target.nodeName !== "A" && e.target.className !== "fa fa-ellipsis-v") {
        this.followerTarget.click();
      }
    }
  }
}
