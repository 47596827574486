<script>
  export default {
    props: ["initialChoices"],
    data: function () {
      let choices = this.initialChoices;
      choices.push({
        label: ""
      });
      return {
        choices: choices
      }
    },
    methods: {
      addChoice: function (e) {
        e.preventDefault();
        this.choices.push({
          label: "",
        });
        this.$nextTick(this.$_focusLastChoice);
      }
      ,
      removeChoiceIfEmptyValue: function (e, index) {
        if (this.choices.length > 1 && e.currentTarget.value.length === 0) {
          this.choices.splice(index, 1);
          this.$nextTick(this.$_focusLastChoice);
          e.preventDefault();
        }
      }
      ,
      $_focusLastChoice: function () {
        let index = this.choices.length - 1;
        this.$refs.choiceLabel[index].focus();
      }
    }
  }
</script>
