<template>
  <div class="evaluation-countdown" v-bind:title="t('js.evaluation.time_remaining')">
    <DoughnutChart :percent="timeRemainingPercent"></DoughnutChart>
    <VueCountdown :time="remainingTime"
                  @progress="handleCountdownProgress"
                  @end="handleCountdownEnd"
                  v-html="timeRemainingLabel"
                  data-behaviour="vue"></VueCountdown>
  </div>
</template>

<script>
  import VueCountdown from '@chenfengyuan/vue-countdown'
  import DoughnutChart from 'vue-doughnut-chart'

  const axios = require('axios');

  export default {
    components: {VueCountdown, DoughnutChart},
    props: [
      "remainingTime",
      "totalTime"
    ],
    data: function () {
      return {
        totalMinutes: Math.floor(this.remainingTime / 1000 / 60),
        totalSeconds: Math.floor(this.remainingTime / 1000)
      }
    },
    methods: {
      handleCountdownProgress(data) {
        this.totalMinutes = data.totalMinutes;
        this.totalSeconds = data.totalSeconds;
      },
      handleCountdownEnd() {
        this.totalMinutes = 0;
        this.totalSeconds = 0;
        let url = document.querySelector("[data-role='terminate']").href;
        axios.put(url).then(() => {
          alert(this.t('js.evaluation.automatically_terminate'));
          window.location.reload();
        });
      }
    },
    computed: {
      timeRemainingLabel() {
        if (this.totalMinutes < 1) {
          return "<span class='time'>" + this.totalSeconds.toString() + "</span> <span class='label'>sec</span>"
        } else {
          return "<span class='time'>" + this.totalMinutes.toString() + "</span> <span class='label'>min</span>"
        }
      },
      timeRemainingPercent() {
        return Math.floor(100 - (100 * this.totalSeconds * 1000) / this.totalTime)
      }
    },
  }
</script>
