const axios = require('axios');

import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["result"]

  search(e) {
    let call;
    if (call) {
      call.cancel();
    }
    call = axios.CancelToken.source();

    let params = {};
    params[e.currentTarget.name] = e.currentTarget.value;

    axios.get(this.url, {
      params: params,
      cancelToken: call.token
    }).then(response => {
      this.resultTarget.innerHTML = response.data;
    });
  }

  get url() {
    return this.data.get("url")
  }
}

