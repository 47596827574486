export default class AssessmentNewForm {
  constructor(scope) {
    this.form = scope.querySelector('form#new_assessment');
  }

  render() {
    if (this.form) {
      let links = this.form.querySelectorAll("[data-behavior='select-tab']");
      links.forEach(link => {
        link.addEventListener("click", function (e) {
          e.preventDefault();
          let navLink = $('.nav a[href="' + link.getAttribute("href") + '"]');
          navLink.tab('show');
          navLink[0].scrollIntoView(true);
        });
      });
    }
  }
};
