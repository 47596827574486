import EasyMDE from 'easymde/dist/easymde.min'
import {CodeJar} from 'codejar/codejar'
import {withLineNumbers} from 'codejar/linenumbers'
import hljs from 'highlight.js'

export default class TextareaEditor {
  constructor(scope) {
    this.textareas = scope.querySelectorAll('[data-editor]')
  }

  render() {
    this.textareas.forEach((textarea) => {
      switch (textarea.dataset.editor) {
        case "markdown":
          this._renderEasyMDEEditor(textarea)
          break

        case "coding":
          this._renderCodeJar(textarea)
          break

        default:
          textarea.addEventListener("input", this._adjustHeight)
      }
    })
  }

  _renderEasyMDEEditor(el) {
    let csrf_token = el.closest("form").querySelector("[name='authenticity_token']").value
    new EasyMDE({
      element: el,
      spellChecker: false,
      forceSync: true,
      autosave: {
        enabled: false,
      },
      renderingConfig: {
        codeSyntaxHighlighting: true,
        hljs: hljs
      },
      minHeight: "200px",
      hideIcons: ['image'],
      showIcons: ["code", "table", "clean-block", "upload-image"],
      imageUploadEndpoint: "/upload_file",
      uploadImage: true,
      imageMaxSize: 1024 * 1024 * 10,
      imageCSRFToken: csrf_token
    })
  }

  _renderCodeJar(el) {
    // CodeJar editor goes into a div element
    const newDiv = document.createElement("div")
    newDiv.classList.add("coding-editor")
    el.parentNode.insertBefore(newDiv, el)
    el.style.display = "none"

    const highlight = editor => {
      // highlight.js does not trims old tags,
      // let's do it by this hack.
      editor.textContent = editor.textContent
      hljs.highlightBlock(editor)
    }
    const jar = new CodeJar(newDiv, withLineNumbers(highlight))
    const displayWarning = function () {
      el.closest("form").querySelector(".warning-editor-not-saved").style.display = "block"
    }

    const localStorageId = `code-${el.id}`
    jar.updateCode(localStorage.getItem(localStorageId))
    jar.onUpdate(code => {
      localStorage.setItem(localStorageId, code)
      el.value = code
      displayWarning()
    })

    if (el.value !== jar.toString()) {
      el.value = jar.toString()
      displayWarning()
    }
  }

  _adjustHeight(e) {
    let el = e.currentTarget
    let offset = el.offsetHeight - el.clientHeight
    if (offset !== 0 && el.scrollHeight !== 0) { // if element is hidden do not apply styles that will compress it further
      el.style.height = 'auto'
      el.style.height = el.scrollHeight + offset + 'px'
    }
  }
}
