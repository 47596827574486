//import Donutty from 'donutty/dist/donutty'
require("donutty/dist/donutty")

import {Controller} from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    this.element.innerHTML = ""; // prevent duplicate donuts when user click "back" on browser
    new Donutty(this.element);
  }
}
