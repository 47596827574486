import {Controller} from "stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["content", "form"]

  connect() {
    this.formTarget.addEventListener("ajax:success", (e) => {
      clearTimeout(this.loader);
      this.contentTarget.innerHTML = e.detail[2].response;
      this.hideLoading();
    });
    this.formTarget.addEventListener("ajax:beforeSend", (e) => {
      if (this.xhr) {
        this.xhr.abort();
      }
      this.xhr = e.detail[0];
    })
    this.replaceHistory = this.data.get("replaceHistory") !== "false";
  }

  search() {
    clearTimeout(this.loader);
    this.loader = setTimeout(this.displayLoading, 300, this);

    clearTimeout(this.searcher);
    this.searcher = setTimeout(this.searching, 0, this) // make works checkboxes when clicking on labels
  }

  searching(self) {
    let formData = new FormData(self.formTarget);
    let search = new URLSearchParams(formData);
    let queryString = search.toString();
    if (self.replaceHistory) {
      history.replaceState({turbolinks: {}}, "", "?" + queryString);
    }
    Rails.fire(self.formTarget, 'submit'); // using `dispatchEvent` seems not working on firefox (form is submit without ajax request)
  }

  displayLoading(self) {
    self.contentTarget.style.opacity = "0.5";
    self.element.querySelector(".live-search-input .svg_icon-search").style.display = "none";
    self.element.querySelector(".live-search-input .loading").style.display = "block";
  }

  hideLoading() {
    this.contentTarget.style.opacity = "1";
    this.element.querySelector(".live-search-input .svg_icon-search").style.display = "block";
    this.element.querySelector(".live-search-input .loading").style.display = "none";
  }
}
