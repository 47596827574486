const axios = require('axios');
import FormSerialize from '../../utils/form_serialize';

export default class FormLiveSearch {
  constructor(scope) {
    this.forms = scope.querySelectorAll("[role='form-live-search']");
    this.call = undefined;
  }

  render() {
    let self = this;
    this.forms.forEach(form => {
      form.querySelectorAll("[type='search'], [type='input'], [type='textarea']")
          .forEach(input => {
            input.addEventListener("keyup", function () {
              self.loadRemoteContent(form);
            });
          });
      form.querySelectorAll("[type='checkbox']")
          .forEach(checkbox => {
            checkbox.addEventListener("change", function () {
              self.loadRemoteContent(form);
            });
          });
    });
  }

  loadRemoteContent(form) {
    let target = document.querySelector(form.dataset.target);
    let self = this;
    if (self.call) {
      self.call.cancel();
    }
    self.call = axios.CancelToken.source();
    self.showSpinner(target);
    self.toggleResetButton(form);
    axios.get(self.formUrl(form) + "&_ajax=1", {
      cancelToken: self.call.token
    }).then(function (response) {
      self.hideSpinner(target);
      target.innerHTML = response.data;
      window.App.setup(form.dataset.target);
    });

  }

  showSpinner(target) {
    clearTimeout(this.spinnerTimeout);
    this.spinnerTimeout = setTimeout(function () {
      target.classList.add("opacity-50");
    }, 300);
  }

  hideSpinner(target) {
    clearTimeout(this.spinnerTimeout);
    target.classList.remove("opacity-50");
  }

  formUrl(form) {
    let params = (new FormSerialize).serialize(form);
    let url = form.action;
    if (url.includes("?")) {
      url = url + "&" + params
    } else {
      url = url + "?" + params
    }
    history.replaceState(null, "", url);
    return(url);
  }

  toggleResetButton(form) {
    form.querySelectorAll("[data-role='reset-form']").forEach(el => {
      el.style.display = "inline"
    });
  }
};
