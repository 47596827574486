const axios = require('axios');

import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["exercise"]

  track() {
    let copiedText = window.getSelection().toString();
    if (copiedText !== "") {
      axios.post(this.exerciseTarget.dataset.url, {
        exercise_id: this.exerciseTarget.dataset.exerciseId,
        copied_text: copiedText
      });
    }
  }
}
