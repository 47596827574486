const axios = require('axios');

import {Controller} from "stimulus"

export default class extends Controller {
  static targets = []

  openModal(e) {
    e.preventDefault();
    let link = e.currentTarget;
    const modal = document.querySelector("#dynamic-modal");
    let target = modal.querySelector('[data-role=modal-content]');
    modal.querySelector(".modal-dialog").classList.add(link.dataset.modalClass);
    target.innerHTML = "<div class='modal-body text-center'><i class='fa fa-spin fa-circle-o-notch'></i></div>";
    axios.get(link.href)
        .then(function (response) {
          target.innerHTML = response.data;
          window.App.setup('[data-role=modal-content]')
        });
    $(modal).modal('show');
    $(modal).off("hidden.bs.modal").on("hidden.bs.modal", function () {
      modal.querySelector(".modal-dialog").classList.remove(link.dataset.modalClass);
    });
  }
}

