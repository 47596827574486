import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["loader"]


  hideLink(e) {
    e.currentTarget.classList.add("disabled")
  }

  replaceHtml(event) {
    let [xhr, status] = event.detail;
    if (status === "OK") {
      this.element.innerHTML = xhr.response;
      let input = this.element.querySelector('form input:not([type=hidden])');
      if (input) {
        input.focus();
      }
    } else {
      alert(xhr.response)
    }
  }
}

