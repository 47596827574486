<template id="exercise-header">
  <div>
    <span v-if="exercise.deleted_at">
      <del v-bind:title="t('shared.exercise.deleted')" data-controller="tooltip">{{exercise.title}}</del>
    </span>
    <span v-else>
      <i v-if="exercise.draft" class="fa fa-eye-slash mr-1 text-muted" v-bind:title="t('shared.exercise.draft')"
         data-controller="tooltip"></i>
      <span v-if="disableLink">
        {{exercise.title}}
      </span>
      <span v-else>
        <a v-bind:href="'/exercises/' + exercise.id" target="_blank" data-controller="dynamic-modal"
           data-action="click->dynamic-modal#openModal" data-modal-class="modal-xl">{{exercise.title}}</a>
      </span>
    </span>
    <small class="text-muted">{{t('activerecord.models.' + underscore(exercise.type))}}</small>
    <div class="mt-1">
      <i class="fa fa-tags mr-2 text-muted"></i>
      <span v-bind:class="['tag tag-level', 'tag-level-' + exercise.level]">{{t('activerecord.attributes.exercise.levels.' + exercise.level)}}</span>
      <span class="tag tag-white">{{exercise.category.name}}</span>
      <a v-for="tag in exercise.tags" class="btn tag tag-with-hover" v-bind:href="'/exercises?query=[' + encodeURIComponent(tag.name) + ']'">{{tag.name}}</a>
    </div>
    <div class="mb-0 text-muted">{{truncatedDescription()}}</div>
  </div>
</template>

<script>
  export default {
    props: {
      exercise: {
        type: Object
      },
      disableLink: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      truncatedDescription: function () {
        return _.truncate(this.exercise.description, {length: 150})
      }
    }
  }
</script>
