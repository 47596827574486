import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["labelToChange"]

  changeLabel(e) {
    let oldContent = this.labelToChangeTarget.innerHTML;
    let newContent = e.currentTarget.dataset.toggleLabelContent;

    this.labelToChangeTarget.innerHTML = newContent;
    e.currentTarget.dataset.toggleLabelContent = oldContent;
  }
}
