import hljs from 'highlight.js'

export default class Highlight {
  constructor(scope) {
    this.codeElements = scope.querySelectorAll('pre code')
  }

  render() {
    this.codeElements.forEach((el) => {
      hljs.highlightBlock(el);
    });
  }
};
