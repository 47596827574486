import I18n from '../../utils/i18n_js.js.erb'

export default class FormFileInput {
  constructor(scope) {
    this.inputs = scope.querySelectorAll('input[type=file]');
  }

  render() {
    this.inputs.forEach(function (input) {
      input.addEventListener("change", function () {
        const count = this.files.length;
        const files = Array.from(this.files).map(x => x.name).join(", ");
        $(this).next(".custom-file-label").text(I18n.t("js.form.file_input.selected_file", {
          count: count,
          files: files
        }));
      });
    });
  }
};
